@import "theme.scss";

html {
  // font-size: 20vw !important;
  height: 100%;
  background: $PageBg;
  
}

body {
  background: $PageBg !important;
}

// 字体
// @font-face {
//   font-family: 'ChangCheng';
//   src: url(/src/font/ChangCheng.eot);
//   src:
//     url(./font/ChangCheng.eot?#font-spider) format('embedded-opentype'),
//     url(./font/ChangCheng.woff) format('woff'),
//     url(./font/ChangCheng.ttf) format('truetype'),
//     url(./font/ChangCheng.svg) format('svg');
// }

// @font-face {
//   font-family: 'XMNumber';
//   src: url(/src/font/Dense-Regular.ttf);
// }
