@import 'theme.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.logo {
  width: 118px;
  height: 118px;
}

.buttons {
  margin-top: 30px;
}