// 字号
$FontSizeS: 0.16rem;
$FontSizeM: 0.19rem;
$FontSizeL: 0.21rem;
$FontSizeXL: 0.23rem;

// navbar高度
$NavBarHeight: 0.5rem;

// 主题颜色
$Primary: #fed32f;
$Secondary: #ffae0d;
$Red: #fe0032;
$Green: #5fb36e;
$Orange: #fe4400;
$Yellow: #ffc65d;
$Brand: #fff;

// 黑色
$BlackS: #141115;
$BlackM: #666;
$BlackL: #999;
$BlackXL: #d6d6d6;
$PageBg: #f5f5f5;
$Divider: #eee;
$White: #fff;

.themeColor {
  color: $Primary;
}

.themeBg {
  background-color: $Primary;
}

.black {
  color: $BlackS;
}

.BlackM {
  color: $BlackM;
}

.error {
  color: $Red;
}

.fontNormal {
  font-size: $FontSizeM;
}

.disable {
  opacity: 0.4;
}

.white {
  color: $White;
}

.whiteBg {
  background-color: $White;
}

img {
  display: block;
}

// #region: 单词换行显示 https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin word-wrap() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  // 需求不要连字符
  // -ms-hyphens: auto;
  // -moz-hyphens: auto;
  // -webkit-hyphens: auto;
  // hyphens: auto;
}

// #region: 单行文字溢出省略号显示（某些浏览器需设置宽度）
@mixin single-line-ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// #region: 多行文本溢出省略号显示
// @param {Number} $num - 展示行数
@mixin multi-line-ellipsis($num) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $num;
  -webkit-box-orient: vertical;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
}
