@import '../../../../theme.scss';

@mixin flexRow {
  display: flex;
  flex-direction: row;
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
}

.modalWrapper {
  :global {
    .am-modal-content {
      background-color: transparent;
      justify-content: center;
      align-items: center;

      @include flexRow();
    }

    .am-modal-transparent,
    .am-modal-content {
      width: 3.6rem;
      padding-top: 0;
    }

    .am-modal-body {
      padding: 0 !important;
    }
  }
}

.modalContainer {
  width: 3.6rem;
  height: 2.74rem;
  background-color: $White;
  border-radius: 0.08rem;
  align-items: center;

  @include flexCol();
}

.modalTitle {
  color: #1a1920;
  font-size: bold;
  font-size: 0.24rem;
  margin-top: 0.25rem;
}

.modalContent {
  margin: 0.17rem 0.59rem 0.23rem 0.47rem;
  color: #111527;
  font-size: $FontSizeL;
  line-height: 0.32rem;
}
